export default {
  methods: {
    signIn() {
      // this.postUser(`/auth/twitter2?origin=${this.getRedirectOrigin()}`);
      this.postUser(`/auth/multi_twitter?origin=${this.getRedirectOrigin()}`);
    },
    signOut() {
      this.postUser(`/auth/logout?origin=${window.location.href}`);
    },
    postUser(path) {
      const config = useRuntimeConfig().public;
      const form = document.createElement("form");
      form.method = "post";
      form.action = `${config.BACKEND_URL}${path}`;

      document.body.appendChild(form);
      form.submit();
    },
    getRedirectOrigin() {
      // redirect to dashboard except in the cases of checkout, archive upload and pricing page
      if (
        this.$route.meta.cmsName === "checkout_page" ||
        this.$route.meta.cmsName === "upload_archive_page" ||
        this.$route.meta.cmsName === "pricing_page"
      )
        return window.location.href;
      else return `${window.location.origin}/dashboard/search-and-delete`;
    },
  },
};
